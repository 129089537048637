<template>
  <div class="list-item-title">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppListItemTitle',
  emits: [],
  props: {},
}
</script>

<style lang="postcss" scoped>
.list-item-title {
  @apply type-subtitle font-bold text-primary-text block;
}
</style>
