<template>
  <AppCard :size="'2xl'">
    <template #header>
      <AppCardHeader>
        <template #left>
          <AppButton theme="icon" @click="goToPendingActions">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
          <div>
            <div class="title-small">
              Pending Actions <span role="img" aria-label="clock">⏰</span>
            </div>
            <div class="title">{{ authorizedPersonnelTitle }}</div>
          </div>
        </template>
      </AppCardHeader>
    </template>

    <!-- Authorized Personnel -->
    <div>
      <p class="input-label">{{ pleaseProvideAuthPersonnelText }}</p>
      <!-- List of Personnel -->
      <AppAlert v-if="isOwnershipLimitExceeded" theme="red" class="owners-alert">
        <AppIcon name="Information Filled" :size="20" class="flex-shrink-0">
          <IconInformationFilled />
        </AppIcon>
        <p class="type-caption">
          You've reached the 100% ownership limit. To add other owners, delete at least one that was
          previously added
        </p>
      </AppAlert>
      <!-- Needs Director Banner -->
      <AppAlert v-if="shouldShowDirectorNeededError" theme="blue" class="owners-alert">
        <AppIcon name="Information Filled" :size="20" class="flex-shrink-0">
          <IconInformationFilled />
        </AppIcon>
        <p class="type-caption text-main-black">
          {{ addAtLeastOneDirectorBannerText }}
        </p>
      </AppAlert>
      <template>
        <div class="authorized-personnel">
          <AppList
            v-for="(item, index) in authorizedPersonnel"
            :key="index"
            class="authorized-personnel-item"
          >
            <AppListClickItem @itemClick="goToAuthorizedPersonnel(item, index)">
              <template #figure>
                <AppFigure
                  data-private
                  :theme="'tertiary-gray'"
                  size="w-14 h-14"
                  class="authorized-caption authorized-caption-initials"
                >
                  {{ getInitials(item.firstName + ' ' + item.lastName) }}
                </AppFigure>
              </template>

              <template #left>
                <AppListItemTitle
                  data-private
                  class="authorized-caption capitalize"
                  v-text="`${item.firstName} ${item.lastName}`"
                ></AppListItemTitle>
                <AppActionStatus
                  v-for="(status, statusIndex) in getPersonnelStatus(item)"
                  :key="statusIndex"
                  class="authorized-status"
                  :status="status"
                ></AppActionStatus>
              </template>

              <template #action>
                <div class="text-text-gray-primary">
                  <AppIcon name="Edit">
                    <IconEdit />
                  </AppIcon>
                </div>
              </template>
            </AppListClickItem>
          </AppList>
        </div>
      </template>
      <!-- Go to add authorize -->
      <div>
        <AppButton theme="text" class="add-authorized-button" @click="goToAddAuthorizedPersonnel">
          <AppIcon class="add-authorized-button-icon" name="Add">
            <IconAdd />
          </AppIcon>
          Add an {{ authorizedPersonnelTitle }}
        </AppButton>
      </div>
    </div>

    <!-- All information correct checkbox -->
    <div
      v-show="
        hasNoOwners ||
        (!hasNoOwners && corpProfile.registrationAddress.country.toUpperCase() === 'GB')
      "
      class="
        flex
        justify-items-center
        items-center
        space-x-2
        p-6
        mb-6
        border border-gray-light
        rounded-2xl
        shadow-ria-1
      "
    >
      <AppInputCheckbox v-model="agreeHasNoOwners" :disabled="isCheckboxDisabled" />
      <p class="type-caption text-gray-primary pl-2">
        {{ checkboxMessage }}
      </p>
    </div>
    <form class="footer-right" @submit.prevent="submit">
      <AppCardFooter class="z-50">
        <AppButton type="submit" :disabled="isSubmitButtonDisabled">
          {{ 'Save & Continue' }}
        </AppButton>
      </AppCardFooter>
    </form>

    <RouterView />
  </AppCard>
</template>

<script>
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import { useMediaQuery } from '@/composables/useMediaQuery'
import {
  IconAdd,
  IconChevronLeft,
  IconEdit,
  IconInformationFilled,
} from '@moneytransfer.ui/euronet-icons'
import { computed, watch, ref } from '@vue/composition-api'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppButton from '@/components/AppButton/AppButton'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import { useStore } from '@/composables/useStore'
import { useRouter } from '@/composables/useRouter'
import AppList from '@/components/AppList/AppList'
import AppListClickItem from '@/components/AppListClickItem/AppListClickItem'
import AppFigure from '@/components/AppFigure/AppFigure'
import AppListItemTitle from '@/components/AppListItemTitle/AppListItemTitle'
import AppActionStatus from '@/components/AppActionStatus/AppActionStatus'
import AppAlert from '@/components/AppAlert/AppAlert'
import AppInputCheckbox from '@/components/AppInputCheckbox/AppInputCheckbox.vue'

import { useAnalyticsStore } from '@/stores/analytics'
import { useDashboardStore } from '@/stores/dashboard'
import { useCountriesStore } from '@/stores/countries'

export default {
  components: {
    AppCard,
    AppCardHeader,
    IconChevronLeft,
    AppIcon,
    AppButton,
    AppCardFooter,
    IconAdd,
    AppList,
    AppListClickItem,
    AppFigure,
    AppListItemTitle,
    AppActionStatus,
    IconEdit,
    AppAlert,
    IconInformationFilled,
    AppInputCheckbox,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const dashboardStore = useDashboardStore()
    const countriesStore = useCountriesStore()

    const authorizedPersonnel = ref([])
    const agreeHasNoOwners = ref(dashboardStore.agreeHasNoOwners)
    const authorizedPersonnelStatus = computed(() =>
      dashboardStore.getIsAdditionalInformationStatusCompleted('authorizedPersonnel')
    )
    const corpProfile = dashboardStore.corpProfile

    const getCountryText = (resourceId, countryCode) => {
      return countriesStore.getCountrySpecificText(resourceId, countryCode)
    }

    const addAtLeastOneDirectorBannerText = ref(
      getCountryText(
        'AddAtLeastOneDirectorBannerText',
        corpProfile.registrationAddress.country.toUpperCase()
      )
    )
    const pleaseProvideAuthPersonnelText = ref(
      getCountryText(
        'PleaseProvideAuthorizedPersonnelText',
        corpProfile.registrationAddress.country.toUpperCase()
      )
    )
    const agreeHasNoOwnerCheckboxText = ref(
      getCountryText(
        'AgreeHasNoOwnerCheckboxText',
        corpProfile.registrationAddress.country.toUpperCase()
      )
    )
    const authorizedPersonnelTitle = ref(
      getCountryText(
        'AuthorizedPersonnelTitle',
        corpProfile.registrationAddress.country.toUpperCase()
      )
    )
    if (!authorizedPersonnelStatus.value) {
      analyticsStore.track({
        event: 'Pending Action Started',
        traits: {
          ActionName: 'Authorized Personnel',
        },
      })
    }

    const isSubmitButtonDisabled = computed(() => {
      // Condition: LLC’s and Sole Proprietorship not adding any authorized personnel
      if (
        (dashboardStore.getIsUsLlcOrSp ||
          dashboardStore.getIsCaSole ||
          dashboardStore.getIsGbSole) &&
        hasNoOwners.value
      ) {
        return !agreeHasNoOwners.value
      }

      // Director Mandatory, Owners optional
      if (dashboardStore.getIsGbLimited) {
        return !(isDirectorAdded.value && agreeHasNoOwners.value)
      }

      if (dashboardStore.getIsCaPrivate) {
        if (!isDirectorAdded.value) {
          return true
        }
      }

      // Condition: Has no owners
      if (hasNoOwners.value) {
        return !authorizedPersonnel.value.length > 0 || !agreeHasNoOwners.value
      }

      return !authorizedPersonnel.value.length > 0
    })

    // Check for authorized personnel on the server response
    const getStoredAuthorizedPersonnel = computed(() => dashboardStore.authorizedPersonnel)

    if (getStoredAuthorizedPersonnel?.value && getStoredAuthorizedPersonnel?.value.length > 0) {
      authorizedPersonnel.value = [...getStoredAuthorizedPersonnel.value]
    }

    // Set Authorized personnel
    watch(
      getStoredAuthorizedPersonnel,
      (data) => {
        if (data?.length && data.length > 0) {
          authorizedPersonnel.value = [...data]
        } else {
          authorizedPersonnel.value = []
        }
        dashboardStore.agreeHasNoOwners = false
        agreeHasNoOwners.value = false
      },
      { deep: true }
    )

    const goToPendingActions = () => {
      router.push({ name: 'PendingActions' })
    }

    const goToAddAuthorizedPersonnel = () => {
      router.push({
        name: 'AuthorizedSignatory',
        params: {
          showModal: true,
          action: 'add',
        },
      })
    }

    const goToAuthorizedPersonnel = (item, index) => {
      let params = null
      if (item) {
        params = { action: 'edit', id: index, authorizedPersonnel: item, index }
      }
      router.push({
        name: 'AuthorizedSignatory',
        params,
      })
    }

    const getInitials = (fullname) => {
      let names = fullname.split(' ')
      let initials = names[0].substring(0, 1).toUpperCase()
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    }

    const getPersonnelStatus = (item) => {
      let personnelStatuses = []

      if (item.isUbo) {
        personnelStatuses.push('owner')
      }

      if (item.isDirector) {
        personnelStatuses.push('director')
      }

      if (!item.isUbo && !item.isDirector) {
        if (item.occupation) {
          personnelStatuses.push(item.occupation)
        }
      }
      return personnelStatuses
    }

    const isOwnershipLimitExceeded = computed(() => dashboardStore.getIsOwnershipLimitExceeded)
    const shouldShowDirectorNeededError = computed(() => dashboardStore.getShouldShowDirectorBanner)
    const hasNoOwners = computed(() => dashboardStore.getUboCount < 1)
    const isDirectorAdded = computed(() => dashboardStore.getIsDirectorAdded)

    const isCheckboxDisabled = computed(() => {
      // Disable for UK when no direct is added
      return (
        !isDirectorAdded.value && corpProfile.registrationAddress.country.toUpperCase() === 'GB'
      )
    })

    const checkboxMessage = computed(() => {
      if (hasNoOwners.value) {
        return agreeHasNoOwnerCheckboxText.value
      }
      if (!hasNoOwners.value && corpProfile.registrationAddress.country.toUpperCase() === 'GB') {
        return 'I certify that all business owners (25% ownership), Directors and people authorised to send money have been added.'
      }
      return ''
    })

    const submit = async () => {
      // If has no owners marks the checkbox as false
      const newAgreeValue = agreeHasNoOwners.value
      // Checkbox:  confirm that no one individual owns 25% or more of the share or ownership interests of an entity that controls the company.
      dashboardStore.agreeHasNoOwners = newAgreeValue
      dashboardStore.setAdditionalInformationStatus('authorizedPersonnel')
      goToPendingActions()
    }

    return {
      mq: useMediaQuery(),
      goToPendingActions,
      goToAddAuthorizedPersonnel,
      goToAuthorizedPersonnel,
      authorizedPersonnel,
      getInitials,
      getPersonnelStatus,
      isOwnershipLimitExceeded,
      agreeHasNoOwners,
      shouldShowDirectorNeededError,
      hasNoOwners,
      addAtLeastOneDirectorBannerText,
      pleaseProvideAuthPersonnelText,
      isSubmitButtonDisabled,
      submit,
      agreeHasNoOwnerCheckboxText,
      corpProfile,
      authorizedPersonnelTitle,
      checkboxMessage,
      isDirectorAdded,
      isCheckboxDisabled,
    }
  },
}
</script>

<style lang="postcss" scoped>
.card-header {
  >>> .card-header-right {
    display: none;
  }
}

.owners-alert {
  @apply bg-tertiary-blue items-start justify-start gap-3 mt-4 !important;
}

.authorized-caption {
  @apply type-body-bold !important;
}

.authorized-caption-initials {
  font-size: 16px !important;
}

.authorized-personnel {
  &-item {
    border-bottom-width: 1px;
    color: rgba(243, 244, 246, 1);
    &:last-child {
      border: unset;
    }
  }
}
.title-small {
  @apply type-subtitle-bold text-text-gray-primary;
  color: #5c667b;
  white-space: nowrap;
}
.title {
  @apply type-h1;
}

.add-authorized-button {
  @apply mb-4 mt-4 text-left !important;
  ::v-deep .button-inner {
    @apply type-caption-bold p-0 pr-2 rounded-2xl !important;
  }
  &-icon {
    @apply rounded-2xl h-auto w-auto p-4 mr-4;
    background-color: #f2f7fe;
  }
}

.input-label {
  @apply text-gray-primary type-body-bold;
}

.card-footer {
  .button {
    ::v-deep&.button--primary {
      .button-inner {
        @apply px-6;
      }
      &:disabled:not(.button--loading) {
        @apply text-white bg-blue-button-disabled !important;
      }
    }
  }

  @screen sm {
    @apply pt-0 !important;
    .button {
      @apply rounded-lg w-full !important;
    }
  }
}

.card-header {
  @apply p-12 !important;
  @apply mb-0 !important;
  >>> .card-header-left {
    .button {
      transform: translateY(-4px);
      background-color: unset;
      .button-inner {
        justify-content: left;
        align-items: baseline;
        .md-ripple-wave {
          display: none;
        }
      }
    }
  }
}

.card {
  max-width: 640px;
  /deep/ .card-content {
    @apply pt-20;
    @apply px-4 pb-12;
    @screen sm {
      @apply pt-0 px-12;
    }
  }
}
.footer-right {
  display: flex;
  justify-content: flex-end;
}
</style>
