<template>
  <li class="list-click-item">
    <button class="list-click-item-button" @click="$emit('itemClick')">
      <div v-if="$slots.figure" class="list-click-item-figure">
        <!-- @slot figure: Slot for figure of content -->
        <slot name="figure"></slot>
      </div>
      <div v-if="$slots.left" class="list-click-item-left">
        <!-- @slot left: Slot for left column -->
        <slot name="left"></slot>
        <div v-if="$slots.center && mq.current === 'xs'">
          <!-- @slot center: Slot for center column -->
          <slot name="center"></slot>
        </div>
      </div>
      <div
        v-if="$slots.center && mq.sm === true"
        class="list-click-item-center"
      >
        <!-- @slot center: Slot for center column -->
        <slot name="center"></slot>
      </div>
      <div v-if="$slots.right && mq.md === true" class="list-click-item-right">
        <!-- @slot right: Slot for right column -->
        <slot name="right"></slot>
      </div>
      <div v-if="$slots.action" class="list-click-item-action">
        <!-- @slot action: Slot for action of content -->
        <slot name="action"></slot>
      </div>
    </button>
    <slot />
  </li>
</template>

<script>
import AppListItem from '@/components/AppListItem/AppListItem'
export default {
  extends: AppListItem,
  emits: ['itemClick'],
}
</script>

<style scoped lang="postcss">
.list-click-item {
  @apply border-b border-solid border-gray-lighter;
  &:last-child {
    @apply border-none;
  }
}
.list-click-item-button {
  @apply flex justify-between items-center text-left min-w-full py-4;
  /* TODO: Check with design on how we want to implement a style for accessability keyboard user*/
  &:focus {
    @apply outline-none;
  }
}
.list + * {
  @apply mt-4;
}
.list-click-item-figure {
  @apply mr-4;
}
.list-click-item-left,
.list-click-item-center,
.list-click-item-right {
  flex: 1;
}
.list-click-item-center {
  @apply text-right;
  @screen md {
    @apply text-center;
  }
}
.list-click-item-right {
  @apply text-right;
}
.list-click-item-action {
  @apply ml-4;
}
</style>
