<template>
  <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
  <label
    class="app-input-checkbox"
    :class="{ disabled }"
    role="checkbox"
    tabindex="0"
    :aria-checked="value"
    :aria-disabled="disabled"
    @keydown="onKeyPressed"
  >
    <input
      :checked="value"
      type="checkbox"
      :disabled="disabled"
      @change="$emit('input', $event.target.checked)"
    />
    <AppRipple class="halo" is-centered :is-disabled="disabled">
      <span class="checkbox">
        <AppIcon class="icon" color="white" size="12">
          <IconCheckmarkFat />
        </AppIcon>
      </span>
    </AppRipple>
    <div class="label">
      <slot />
    </div>
  </label>
</template>

<script>
import { IconCheckmarkFat } from '@moneytransfer.ui/euronet-icons'
import AppIcon from '@/components/AppIcon/AppIcon.vue'
export default {
  name: 'AppInputCheckbox',
  components: {
    AppIcon,
    IconCheckmarkFat,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const onKeyPressed = (e) => {
      if (e.key === ' ' || e.key === 'Enter') {
        emit('input', !props.value)
      }
    }
    return { onKeyPressed }
  },
}
</script>

<style lang="postcss" scoped>
.app-input-checkbox {
  @apply relative flex items-center;
  input[type='checkbox'] {
    @apply hidden;
  }
  .halo {
    @apply inline-flex justify-center items-center w-6 h-6 rounded-full bg-transparent;
    &:after {
      content: '';
      @apply absolute w-6 h-6 bg-gray-lighter rounded-full;
      @apply transform scale-0 transition-transform ease-in-out duration-180;
    }
    .md-ripple-enter {
      @apply opacity-100;
    }
    .md-ripple-wave {
      @apply bg-gray-lighter;
    }
    .checkbox {
      @apply inline-flex justify-center items-center w-5 h-5 border-2 rounded-lg border-secondary-text z-10;
      .icon {
        @apply opacity-0 transition-opacity duration-180;
      }
    }
  }
  input[type='checkbox']:checked ~ .halo {
    &:after {
      @apply bg-orange-lighter;
    }
    .md-ripple-wave {
      @apply bg-orange-lighter;
    }
    .checkbox {
      @apply bg-orange border-orange;
      .icon {
        @apply opacity-100;
      }
    }
  }
  input[type='checkbox']:disabled ~ .halo {
    &:after {
      @apply hidden;
    }
    .checkbox {
      @apply border-gray-light;
    }
  }
  input[type='checkbox']:checked:disabled ~ span {
    @apply bg-gray-light border-transparent;
  }
  &:hover .halo:after,
  &:focus .halo:after {
    @apply scale-100;
  }
  &.disabled {
    @apply cursor-not-allowed;
  }
  &:focus {
    @apply outline-none;
  }
}
</style>
