<template>
  <header
    class="card-header"
    :class="{ 'card-header--without-title': !$slots.default }"
  >
    <div class="card-header-left">
      <!-- @slot left: For action buttons-->
      <slot name="left"></slot>
    </div>
    <div class="card-header-title">
      <!-- @slot default: For optional title -->
      <slot></slot>
    </div>
    <div class="card-header-right">
      <!-- @slot right: For action buttons -->
      <slot name="right"></slot>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppCardHeader',
  emits: [''],
  props: {
    showBackButton: {
      type: Boolean,
    },
  },
}
</script>

<style scoped>
.card-header {
  @apply pt-7 px-7;
  @apply justify-center items-center w-full;

  /* hidden on xs device */
  @apply hidden;

  /* Show on sm up*/
  @screen sm {
    @apply flex;
  }

  @apply mb-5;
  &.card-header--without-title {
    @apply -mb-1;
  }

  .card-header-title {
    @apply type-h2;
  }

  .card-header-left,
  .card-header-right {
    @apply flex-1;
  }

  .card-header-left {
    @apply flex justify-start;
  }

  .card-header-right {
    @apply flex justify-end;
  }
}
</style>
