<template>
  <figure class="figure" :class="[figureTheme, size]">
    <!-- @slot default: Icon or text here -->
    <slot />
  </figure>
</template>

<script>
import { computed } from '@vue/composition-api'
export const themes = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'pink', 'gray']
export default {
  name: 'AppFigure',
  components: {},
  props: {
    theme: {
      type: String,
      default: 'red',
      validation: (value) => {
        return themes.indexOf(value) > -1
      },
    },
    size: {
      type: String,
      default: 'w-16 h-16',
    },
  },
  setup(props) {
    const figureTheme = computed(() => {
      return `figure--${props.theme}`
    })
    return {
      figureTheme,
    }
  },
}
</script>

<style scoped lang="postcss">
.figure {
  @apply type-body font-bold rounded-xl flex items-center justify-center;
}
.figure--red {
  @apply text-red-text bg-red-lighter;
}
.figure--orange {
  @apply text-orange-text bg-orange-lighter;
}
.figure--yellow {
  @apply text-yellow-text bg-yellow-lighter;
}
.figure--green {
  @apply text-green-text bg-green-lighter;
}
.figure--blue {
  @apply text-blue-text bg-blue-lighter;
}
.figure--purple {
  @apply text-purple-text bg-purple-lighter;
}
.figure--pink {
  @apply text-pink-text bg-pink-lighter;
}
.figure--gray {
  @apply text-secondary-text bg-gray-lighter;
}

.figure--tertiary-gray {
  @apply bg-tertiary-gray-lighter border-tertiary-gray-darker border text-text-gray-primary;
}
</style>
