<template>
  <li class="list-item">
    <div v-if="$slots.figure" class="list-item-figure">
      <!-- @slot figure: Slot for figure of content -->
      <slot name="figure"></slot>
    </div>
    <div v-if="$slots.left" class="list-item-left">
      <!-- @slot left: Slot for left column -->
      <slot name="left"></slot>
      <div v-if="$slots.center && mq.current === 'xs'">
        <!-- @slot center: Slot for center column -->
        <slot name="center"></slot>
      </div>
    </div>
    <div v-if="$slots.center && mq.sm === true" class="list-item-center">
      <!-- @slot center: Slot for center column -->
      <slot name="center"></slot>
    </div>
    <div v-if="$slots.right && mq.md === true" class="list-item-right">
      <!-- @slot right: Slot for right column -->
      <slot name="right"></slot>
    </div>
    <div v-if="$slots.action" class="list-item-action">
      <!-- @slot action: Slot for action of content -->
      <slot name="action"></slot>
    </div>
  </li>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { useMediaQuery } from '@/composables/useMediaQuery'

export default {
  setup() {
    return {
      mq: reactive(useMediaQuery()),
    }
  },
}
</script>

<style scoped lang="postcss">
.list-item {
  @apply flex justify-between items-center border-b border-solid py-4 border-gray-lighter;
  &:last-child {
    @apply border-none;
  }
}
.list-item-figure {
  @apply mr-4;
}
.list-item-left,
.list-item-center,
.list-item-right {
  flex: 1;
}
.list-item-center {
  @apply text-right;
  @screen md {
    @apply text-center;
  }
}
.list-item-right {
  @apply text-right;
}
.list-item-action {
  @apply ml-4;
}
</style>
